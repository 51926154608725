@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.tag {
  user-select: none;
  font-size: 12px;
  align-items: center;
  border-radius: 20px;
  display: inline-flex;
  user-select: none;
  white-space: nowrap;
}

.tooltip {
  font-size: 18px;
  background-color: white;
  border: 1px solid $malibu;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.tagIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  line-height: 10px;
  margin-right: 8px;
  width: 20px;
}
.quantity {
  width: 24px;
  height: 24px;
  background: var(--White, #fff);
  border-radius: 100%;
  margin-left: 4px;
  color: var(--Blue, #8080ff);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
