.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  width: 617px;
  height: 604px;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 56px 64px -48px rgba(109, 121, 158, 0.14);
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 76.114px;
  height: 18px;
  position: relative;
  right: 20px;
}

.newBtn {
  width: fit-content;
  padding: 6px;
  border-radius: 6px;
  background: #8080ff;
  color: #fff;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
}

.textSection {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  width: 303px;
  color: #25262d;
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.24px;
  text-align: center;
}

.subtitle {
  margin-top: 20px;
  color: #25262d;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
}

.blueBackground {
  width: 569px;
  height: 320px;
  border-radius: 10px;
  border: 1px solid #eef1fe;
  background: #dbddff;
  margin-top: 30px;
  position: relative;
}

.backgroundSvg {
  z-index: 9999;
  position: absolute;
  top: 30px;
  right: 30px;
}

.btnsWrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sampleBtn {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.sampleText {
  color: #8080ff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
}

.rightBtns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cancel {
  color: #25262d;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
}

.uploadBtn {
  width: fit-content;
  padding: 10px 16px;
  border-radius: 10px;
  background: #8080ff;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  cursor: pointer;
}
